// @ts-nocheck
import { createRef, useRef, useState, useEffect } from "react"
import { observer } from 'mobx-react-lite'

/* STYLES */
import styles from './styles/Header.module.css';
import classNames from 'classnames/bind';
import {TypeAnimation} from "react-type-animation";
let cx = classNames.bind(styles);



const Header = observer (({ component: Buttons, ...rest }: any) => {

  return (
    <header className={ cx("header-wrapper") }>

      <div className={ cx("logo") }>
          <span className={ cx("fav") }></span>
        <h1>copenic</h1>
      </div>

        <div className={ cx("noise") }></div>

        <div className={ cx("system") }></div>

    </header>

  );
});

export default Header;
