import { useContext } from "react";
import { Redirect, Route } from 'react-router-dom';
import { IonApp, setupIonicReact, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { RootStoreContext } from "./services/RootStore";
import { observer } from 'mobx-react-lite'

/* Pages */
import Splash from './pages/Splash';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/global.css';
import './theme/variables.css';
import './theme/fonts.css';

setupIonicReact();

const App: React.FC = observer (() => {
    return (<Splash />);
});


export default App;
