import { createContext } from "react"
import { makeAutoObservable } from "mobx"


class RootStore {

  error = {};
  isMobile = false;
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
    this.isLoaded = true;

  }

};

const  RootStoreContext = createContext<RootStore>({} as any)

export  { RootStoreContext, RootStore };
