import {  useState, useEffect, useRef } from "react"
import { observer } from 'mobx-react-lite'
import styles from './styles/Splash_progress.module.css';
import classNames from 'classnames/bind';
import Header from '../components/Header';
import Tagline from '../components/Tagline';
import Nav from '../components/Nav';
import Sidebar from '../components/Sidebar';
import { IonContent } from '@ionic/react';
import { ParallaxProvider } from 'react-scroll-parallax';

/* ----- CHECKOUT PROVIDERS ---- */
import { motion, useScroll, useTransform } from "framer-motion"


let cx = classNames.bind(styles);



interface Props {

}

const Splash: React.FC<Props> = observer ((props) => {

    const scrollRef = useRef(null)
    const { scrollYProgress } = useScroll({
      container: scrollRef
    })

    const input = [0,  1];
    const output = [1, 0];
    const opacity = useTransform(scrollYProgress, input, output)

    const outputNav = [0, 4]
    const opacityNav = useTransform(scrollYProgress, input, outputNav)
    const filter = useTransform(scrollYProgress, v => `grayscale(${v*6})`);

    return (
      <ParallaxProvider>
        <IonContent >
            <Header />
        </IonContent>
      </ParallaxProvider>
  );
});

export default Splash;
